export const PROVINCES = [
  'Ahvenanmaa',
  'Etelä-Karjala',
  'Etelä-Pohjanmaa',
  'Etelä-Savo',
  'Kainuu',
  'Kanta-Häme',
  'Keski-Pohjanmaa',
  'Keski-Suomi',
  'Kymenlaakso',
  'Lappi',
  'Pirkanmaa',
  'Pohjanmaa',
  'Pohjois-Karjala',
  'Pohjois-Pohjanmaa',
  'Pohjois-Savo',
  'Päijät-Häme',
  'Satakunta',
  'Uusimaa',
  'Varsinais-Suomi'
];

export const CITIES = {
  ahvenanmaa: [
    'Maarianhamina',
    'Finström',
    'Geta',
    'Hammarland',
    'Jomala',
    'Saltvik',
    'Sund',
    'Eckerö',
    'Lemland',
    'Lumparland',
    'Brändö',
    'Föglö',
    'Kumlinge',
    'Kökar',
    'Sottunga',
    'Vårdö',
  ],
  etela_karjala: [
    'Imatra',
    'Lappeenranta',
    'Lemi',
    'Luumäki',
    'Parikkala',
    'Rautjärvi',
    'Ruokolahti',
    'Savitaipale',
    'Taipalsaari',
  ],
  etela_pohjanmaa: [
    'Alajärvi',
    'Töysä',
    'Evijärvi',
    'Ilmajoki',
    'Isojoki',
    'Karijoki',
    'Kauhajoki',
    'Kauhava',
    'Kuortane',
    'Kurikka',
    'Lappajärvi',
    'Seinäjoki',
    'Soini',
    'Teuva',
    'Vimpeli',
    'Ähtäri',
  ],
  etela_savo: [
    'Enonkoski',
    'Heinävesi',
    'Hirvensalmi',
    'Juva',
    'Kangasniemi',
    'Mikkeli',
    'Mäntyharju',
    'Pertunmaa',
    'Pieksämäki',
    'Puumala',
    'Rantasalmi',
    'Savonlinna',
    'Sulkava',
  ],
  kainuu: [
    'Hyrynsalmi',
    'Kajaani',
    'Kuhmo',
    'Paltamo',
    'Puolanka',
    'Ristijärvi',
    'Sotkamo',
    'Suomussalmi',
  ],
  kanta_hame: [
    'Forssa',
    'Hattula',
    'Hausjärvi',
    'Humppila',
    'Hämeenlinna',
    'Janakkala',
    'Jokioinen',
    'Loppi',
    'Riihimäki',
    'Tammela',
    'Ypäjä',
  ],
  keski_pohjanmaa: [
    'Halsua',
    'Kannus',
    'Kaustinen',
    'Kokkola',
    'Lestijärvi',
    'Perho',
    'Toholampi',
    'Veteli',
  ],
  keskisuomi: [
    'Hankasalmi',
    'Joutsa',
    'Jyväskylä',
    'Jämsänkoski',
    'Kannonkoski',
    'Karstula',
    'Keuruu',
    'Kivijärvi',
    'Konnevesi',
    'Kuhmoinen',
    'Kyyjärvi',
    'Laukaa',
    'Luhanka',
    'Multia',
    'Muurame',
    'Petäjävesi',
    'Pihtipudas',
    'Saarijärvi',
    'Toivakka',
    'Uurainen',
    'Viitasaari',
    'Äänekoski',
  ],
  kymenlaakso: [
    'Hamina',
    'Iitti',
    'Kotka',
    'Kouvola',
    'Miehikkälä',
    'Pyhtää',
    'Virolahti',
  ],
  lappi: [
    'Enontekiö',
    'Inari',
    'Kemi',
    'Kemijärvi',
    'Keminmaa',
    'Kittilä',
    'Kolari',
    'Muonio',
    'Pelkosenniemi',
    'Pello',
    'Posio',
    'Rovaniemi',
    'Salla',
    'Savukoski',
    'Simo',
    'Sodankylä',
    'Tervola',
    'Tornio',
    'Utsjoki',
    'Ylitornio',
  ],
  paijat_hame: [
    'Asikkala',
    'Hartola',
    'Heinola',
    'Hollola',
    'Kärkölä',
    'Lahti',
    'Orimattila',
    'Padasjoki',
    'Sysmä',
  ],
  pirkanmaa: [
    'Akaa',
    'Hämeenkyrö',
    'Ikaalinen',
    'Juupajoki',
    'Kangasala',
    'Kihniö',
    'Lempäälä',
    'Mänttä-Vilppula',
    'Nokia',
    'Orivesi',
    'Parkano',
    'Pirkkala',
    'Punkalaidun',
    'Pälkäne',
    'Ruovesi',
    'Sastamala',
    'Tampere',
    'Urjala',
    'Valkeakoski',
    'Vesilahti',
    'Virrat',
    'Ylöjärvi',
  ],
  pohjanmaa: [
    'Isokyrö',
    'Kaskinen',
    'Korsnäs',
    'Kristiinankaupunki',
    'Kruunupyy',
    'Laihia',
    'Luoto',
    'Maalahti',
    'Mustasaari',
    'Närpiö',
    'Pedersöre',
    'Pietarsaari',
    'Uusikaarlepyy',
    'Vaasa',
    'Vöyri',
  ],
  pohjois_karjala: [
    'Ilomantsi',
    'Joensuu',
    'Juuka',
    'Kitee',
    'Kontiolahti',
    'Lieksa',
    'Liperi',
    'Nurmes',
    'Outokumpu',
    'Polvijärvi',
    'Rääkkylä',
    'Tohmajärvi',
    'Valtimo',
  ],
  pohjois_pohjanmaa: [
    'Alavieska',
    'Haapajärvi',
    'Haapavesi',
    'Hailuoto',
    'Kuivaniemi',
    'Himanka',
    'Kempele',
    'Kuusamo',
    'Kärsämäki',
    'Liminka',
    'Lumijoki',
    'Merijärvi',
    'Muhos',
    'Nivala',
    'Oulainen',
    'Oulu',
    'Pudasjärvi',
    'Pyhäjoki',
    'Pyhäjärvi',
    'Pyhäntä',
    'Raahe',
    'Reisjärvi',
    'Sievi',
    'Siikajoki',
    'Siikalatva',
    'Taivalkoski',
    'Tyrnävä',
    'Utajärvi',
    'Vaala',
    'Ylivieska',
  ],
  pohjois_savo: [
    'Iisalmi',
    'Kaavi',
    'Keitele',
    'Kiuruvesi',
    'Kuopio',
    'Lapinlahti',
    'Leppävirta',
    'Pielavesi',
    'Rautalampi',
    'Rautavaara',
    'Siilinjärvi',
    'Sonkajärvi',
    'Suonenjoki',
    'Tervo',
    'Tuusniemi',
    'Varkaus',
    'Vesanto',
    'Vieremä',
  ],
  satakunta: [
    'Eura',
    'Harjavalta',
    'Honkajoki',
    'Huittinen',
    'Jämijärvi',
    'Kankaanpää',
    'Karvia',
    'Kokemäki',
    'Luvia',
    'Merikarvia',
    'Nakkila',
    'Pomarkku',
    'Pori',
    'Rauma',
    'Säkylä',
    'Siikainen',
    'Ulvila',
  ],
  uusimaa: [
    'Askola',
    'Espoo',
    'Hanko',
    'Helsinki',
    'Hyvinkää',
    'Inkoo',
    'Järvenpää',
    'Karkkila',
    'Kauniainen',
    'Kerava',
    'Kirkkonummi',
    'Lapinjärvi',
    'Lohja',
    'Loviisa',
    'Mäntsälä',
    'Myrskylä',
    'Nurmijärvi',
    'Pornainen',
    'Porvoo',
    'Pukkila',
    'Raasepori',
    'Sipoo',
    'Siuntio',
    'Tuusula',
    'Vantaa',
    'Vihti',
  ],
  varsinais_suomi: [
    'Aura',
    'Kaarina',
    'Kemiönsaari',
    'Koski Tl',
    'Kustavi',
    'Laitila',
    'Lieto',
    'Loimaa',
    'Marttila',
    'Masku',
    'Mynämäki',
    'Naantali',
    'Nousiainen',
    'Oripää',
    'Paimio',
    'Pöytyä',
    'Pyhäranta',
    'Raisio',
    'Rusko',
    'Salo',
    'Sauvo',
    'Somero',
    'Taivassalo',
    'Turku',
    'Uusikaupunki',
    'Vehmaa',
  ]
};

export const SPECIES = [
  'Kissat',
  'Koirat',
  'Jyrsijät',
  'Linnut',
  'Matelijat',
  'Kalat',
  'Muut',
];

export const RACES = {
  dogs: [
    'Alaskanhusky',
    'Kanariandoggi',
    'Meksikonkarvatonkoira',
    'Amerikanbully',
    'Tornjak',
    'Dogo argentino',
    'Kääpiömäyräkoira',
    'Brasilianterrieri',
    'Amerikanpitbullterrieri',
    'Amerikanbulldoggi',
    'Afgaaninvinttikoira',
    'Airedalenterrieri',
    'Akita',
    'Alaskanmalamuutti',
    'Amerikanakita',
    'Amerikancockerspanieli',
    'Amerikankarvatonterrieri',
    'Amerikankettukoira',
    'Amerikanrottaterrieri',
    'Amerikanstaffordshirenterrieri',
    'Apinapinseri',
    'Appenzellinpaimenkoira',
    'Australiankelpie',
    'Australianpaimenkoira',
    'Australianterrieri',
    'Barbet',
    'Basset artésien normand',
    'Basset hound',
    'Beagle',
    'Beaucenpaimenkoira',
    'Bedlingtoninterrieri',
    'Belgianpaimenkoira',
    'Berninpaimenkoira',
    'Bichon frisé',
    'Bordeauxindoggi',
    'Bokseri',
    'Bostoninterrieri',
    'Saksanpaimenkoira',
    'Sekarotuinen',
    'Cairnterrieri',
    'Cane corso',
    'Cavalier kingcharlesinspanieli',
    'Ceskyterrieri',
    'Ceskoslovensky vlciak',
    'Chesapeakelahdennoutaja',
    'Chihuahua, lyhytkarvainen',
    'Chihuahua, pitkäkarvainen',
    'Chow chow',
    'Cockerspanieli',
    'Collie, pitkäkarvainen',
    'Collie, sileäkarvainen',
    'Coton de tuléar',
    'Dalmatiankoira',
    'Dandiedinmontinterrieri',
    'Dobermanni',
    'Dreeveri',
    'Dunkerinajokoira',
    'Eestinajokoira',
    'Englanninbulldoggi',
    'Englanninkääpiöterrieri',
    'Etelävenäjänkoira',
    'Englanninsetteri',
    'Englanninspringerspanieli',
    'Englanninvinttikoira',
    'Espanjanmastiffi',
    'Espanjanvesikoira',
    'Espanjanvinttikoira',
    'Estrelanvuoristokoira',
    'Etnankoira',
    'Eurasier',
    'Faaraokoira',
    'Fieldspanieli',
    'Gascognenbassetti',
    'Grönlanninkoira',
    'Glen of imaalinterrieri',
    'Gordoninsetteri',
    'Harmaa norjanhirvikoira',
    'Havannankoira, bichon havanais',
    'Hokkaido',
    'Hollanninpaimenkoira',
    'Hovawart',
    'Hälleforsinkoira',
    'Ibizanpodenco',
    'Irlanninsusikoira',
    'Irlanninterrieri',
    'Islanninlammaskoira',
    'Isomünsterinseisoja',
    'Isosveitsinpaimenkoira',
    'Italianseisoja',
    'Italianvinttikoira',
    'Itäsiperianlaika',
    'Jackrussellinterrieri',
    'Jakutianlaika',
    'Japanese chin',
    'Japaninpystykorva',
    'Jämtlanninpystykorva',
    'Karjalankarhukoira',
    'Karkeakarvainen saksanseisoja',
    'Kaukasiankoira',
    'Keeshond',
    'Kerrynterrieri',
    'Keskiaasiankoira',
    'Kettuterrieri',
    'Kiharakarvainen noutaja',
    'Kiinanharjakoira',
    'Kiinanpalatsikoira, pekingeesi',
    'Kingcharlesinspanieli',
    'Kleinspitz',
    'Kooikerhondje',
    'Korthalsingriffoni',
    'Kultainennoutaja',
    'Kuvasz',
    'Kääpiöbullterrieri',
    'Kääpiöpinseri',
    'Kääpiösnautseri',
    'Kääpiövillakoira',
    'Isovillakoira',
    'Labradorinnoutaja',
    'Lagotto romagnolo',
    'Lakelandinterrieri',
    'Lancashirenkarjakoira',
    'Landseer',
    'Lapinporokoira',
    'Leonberginkoira',
    'Lhasa apso',
    'Lunnikoira',
    'Lyhytkarvainen saksanseisoja',
    'Länsigöötanmaanpystykorva',
    'Länsisiperianlaika',
    'Löwchen',
    'Maltankoira',
    'Manchesterinterrieri',
    'Maremmano-abruzzese',
    'Mastiffi',
    'Mittelspitz',
    'Mopsi',
    'Mudi',
    'Musta norjanhirvikoira',
    'Mäyräkoira',
    'Napolinmastiffi',
    'Newfoundlandinkoira',
    'Norfolkinterrieri',
    'Norwichinterrieri',
    'Novascotiannoutaja',
    'Papillon ja phalène',
    'Parsonrussellinterrieri',
    'Partacollie',
    'Petit basset griffon vendeen',
    'Pienimünsterinseisoja',
    'Pinseri',
    'Pitkäkarvainen saksanseisoja',
    'Pohjanpystykorva',
    'Pointteri',
    'Polski owczarek nizinny',
    'Pomeranian',
    'Portugalinpodengo',
    'Portugalinvesikoira',
    'Prahanrottakoira',
    'Pumi',
    'Punainen irlanninsetteri',
    'Punavalkoinen irlanninsetteri',
    'Puolanvinttikoira',
    'Pyreneittenkoira',
    'Pyreneittenmastiffi',
    'Pyreneittenpaimenkoira',
    'Ranskanbulldoggi',
    'Rhodesiankoira',
    'Rottweiler',
    'Saksanmetsästysterrieri',
    'Saluki',
    'Samojedinkoira',
    'Sarplaninac',
    'Schapendoes',
    'Schipperke',
    'Sealyhaminterrieri',
    'Shetlanninlammaskoira',
    'Shiba',
    'Shih tzu',
    'Sileäkarvainen noutaja',
    'Silkkiterrieri',
    'Siperianhusky',
    'Skotlanninhirvikoira',
    'Skotlanninterrieri',
    'Skyenterrieri',
    'Sloughi',
    'Slovakiancuvac',
    'Snautseri',
    'Spinone',
    'Stabijhoun',
    'Staffordshirenbullterrieri',
    'Suomenajokoira',
    'Suomenlapinkoira',
    'Suomenpystykorva',
    'Suursnautseri',
    'Tanskalais-ruotsalainen pihakoira',
    'Tanskandoggi',
    'Tiibetinmastiffi',
    'Tiibetinspanieli',
    'Tiibetinterrieri',
    'Tosa',
    'Tsekinpaimenkoira',
    'Unkarinvinttikoira',
    'Unkarinvizsla',
    'Valkoinen länsiylämaanterrieri',
    'Valkoinen ruotsinhirvikoira',
    'Valkoinenpaimenkoira',
    'Vanhaenglanninlammaskoira',
    'Vehnäterrieri',
    'Venäjänbolonka',
    'Venäjänmustaterrieri',
    'Venäjänspanieli',
    'Venäjäntoy',
    'Venäjänvinttikoira',
    'Venäläis-eurooppalainen laika',
    'Vihikoira',
    'Villakoira',
    'Volpino italiano',
    'Walesinterrieri',
    'Weimarinseisoja',
    'Welsh corgi cardigan',
    'Welsh corgi pembroke',
    'Whippet',
    'Walesinspringerspanieli',
    'Yorkshirenterrieri'
  ],
  cats: [
    'Kotikissa',
    'Exotic',
    'Persialainen',
    'Pyhä Birma',
    'Ragdoll',
    'Scottish fold',
    'Scottish straight',
    'Turkkilainen Van',
    'American Curl, lyhytkarva',
    'American Curl, pitkäkarva',
    'LaPerm',
    'Maine Coon',
    'Neva Masquerade',
    'Norjalainen metsäkissa',
    'Siperian kissa',
    'Turkkilainen angora',
    'Bengali',
    'Eurooppalainen',
    'Ocicat',
    'Brittiläinen lyhytkarva',
    'Kartusiaani',
    'Singapura',
    'Brittiläinen pitkäkarva',
    'Korat',
    'Snowshoe',
    'Burma',
    'Kuriilien bobtail, pitkäkarva',
    'Sokoke',
    'Savannah',
    'Burmilla',
    'Kuriilien bobtail, lyhytkarva',
    'Selkirk rex, lyhytkarva',
    'Egyptinmau',
    'Manx',
    'Selkirk rex, pitkäkarva',
    'Cymric',
    'Balineesi',
    'Cornish rex',
    'Devon rex',
    'Don Sfinx',
    'German rex',
    'Itämainen lyhytkarva',
    'Itämainen pitkäkarva',
    'Japanin bobtail',
    'Peterbald',
    'Sfinx',
    'Siamilainen',
    'Somali',
    'Thai',
    'Venäjänsininen',
  ],
  gnawers: [
    'Chinchilla',
    'Talvikko',
    'Hamsteri',
    'Marsu',
    'Kesyhiiri',
    'Kesyrotta',
    'Gerbiili',
    'Degu',
  ],
  birds: [
    'Aavikkoloistokaija',
    'Afrikankaija',
    'Akaasiavahanokka',
    'Adelaidenrosella',
    'Amatsonianaranen',
    'Amazonianamatsoni',
    'Amazonin puuboa',
    'Andienamatsoni',
    'Angolankaija',
    'Angolanruusupeippo',
    'Arabianvahanokka',
    'Araukaria-amatsoni',
    'Arfakinmanikki',
    'Atsteekkiaratti',
    'Australiankuningaskaija',
    'Australianruokopeippo',
    'Avoparikaija',
    'Beo',
    'Bolivianara',
    'Borneonmanikki',
    'Brasilianara',
    'Brasilian sateenkaariboa',
    'Brasilianaratti',
    'Celebesinkakadu',
    'Costaricanaratti',
    'Dominicanamatsoni',
    'Ecuadorinaranen',
    'Epolettikaija',
    'Etelänaratti',
    'Etiopiankaija',
    'Fidzinparatiisipeippo',
    'Haitinamatsoni',
    'Haitinaratti',
    'Harlekiinipeippo',
    'Harmaamanikki',
    'Harmaapapukaija',
    'Harmaapääaratti',
    'Harmaaruusupeippo',
    'Harmaatäpläpeippo',
    'Harmaavahanokka',
    'Helmiseeprapeippo',
    'Helmituliperä',
    'Helmitöpöpeippo',
    'Hirssihemppo',
    'Hirssivahanokka',
    'Hopeanokka',
    'Huppuhopeanokka',
    'Huppuoliivipeippo',
    'Hyasinttiara',
    'Idänhopeanokka',
    'Indigoara',
    'Isoafrikankaija',
    'Isokaijanen',
    'Isokauluskaija',
    'Isonokipeippo',
    'Isonokkamanikki',
    'Isopunapeippo',
    'Isoviheraratti',
    'Jamaikanamatsoni',
    'Jauhoamatsoni',
    'Joikuara',
    'Jokiamatsoni',
    'Jokikaijanen',
    'Jukutaninamatsoni',
    'Kaktusaratti',
    'Kallioaratti',
    'Kalvakkamanikki',
    'Kameruninkaija',
    'Kanarianhemppo',
    'Kastanjamanikki',
    'Kauluskaija',
    'Kaunoamatsoni',
    'Keisariamatsoni',
    'Kekonokkakutoja',
    'Keltakurkkurosella',
    'Keltaniska-amatsoni',
    'Keltarosella',
    'Keltasiipipeippo',
    'Kobolttipeippo',
    'Kolumbianaranen',
    'Komoriensinikyyhky',
    'Kongonkaija',
    'Kongonvahanokka',
    'Kruunuaratti',
    'Kruunuviherkaija',
    'Siniaratti',
    'Kulta-aratti',
    'Kultaloistokaija',
    'Kultanaama-amatsoni',
    'Kultaposkiamatsoni',
    'Kultasiipipeippo',
    'Kultatiikeripeippo',
    'Kultatöyhtökakadu',
    'Kuubanamatsoni',
    'Kuubanaratti',
    'Liekkipyrstöamatsoni',
    'Lila-amatsoni',
    'Loiskutoja',
    'Loistokaija',
    'Loistomurskaajapeippo',
    'Lokkipeippo',
    'Luhtavahanokka',
    'Luumukyyhky',
    'Luumupääkaija',
    'Luzoninparatiisipeippo',
    'Lyijyvahanokka',
    'Madagaskarinkaijanen',
    'Madagaskarinpeippo',
    'Madagaskarinsinikyyhky',
    'Malinruusupeippo',
    'Meksikonamatsoni',
    'Meksikonaranen',
    'Melanesianparatiisipeippo',
    'Metsäsininokka',
    'Metsävahanokka',
    'Mindanaonparatiisipeippo',
    'Molukkienkakadu',
    'Molukkienkuningaskaija',
    'Mosambikinkaija',
    'Munkkiaratti',
    'Mustahuppumanikki',
    'Mustakaulamanikki',
    'Mustakurkkupeippo',
    'Mustalakkiaratti',
    'Mustamanikki',
    'Mustanokka-amatsoni',
    'Mustavyömanikki',
    'Muurahaispeippo',
    'Naamiokaijanen',
    'Naamioruusupeippo',
    'Naamioseeprapeippo',
    'Naamiotuliperä',
    'Naamiovahanokka',
    'Naurukyyhky',
    'Neitokakadu',
    'Nigerianvahanokka',
    'Niittymanikki',
    'Nokimanikki',
    'Nokipeippo',
    'Nokiperäpeippo',
    'Nokipunapeippo',
    'Nyökyttäjäpeippo',
    'Oliivituliperä',
    'Oliivivahanokka',
    'Orvokkipeippo',
    'Pahta-aratti',
    'Paimenruohokaija',
    'Palettipeippo',
    'Palmuaratti',
    'Papuankuningaskaija',
    'Papuanparatiisipeippo',
    'Papuantuliperä',
    'Parana-ara',
    'Paratiisipeippo',
    'Parviaranen',
    'Patagonianaratti',
    'Pensassininokka',
    'Persikka-aratti',
    'Persikkakaijanen',
    'Perunaranen',
    'Pihamaina',
    'Pikkuara',
    'Pikkumurskaajapeippo',
    'Pikkuoliivipeippo',
    'Pikkupunapeippo',
    'Pilkkuvatsapeippo',
    'Pitsimanikki',
    'Pronssimanikki',
    'Puertoriconamatsoni',
    'Pumpulirintamanikki',
    'Puna-ara',
    'Punahelmipeippo',
    'Punamurskaajapeippo',
    'Punamuurahaispeippo',
    'Punaotsa-amatsoni',
    'Punapeippo',
    'Punapiispa',
    'Punaposkipeippo',
    'Punapääaratti',
    'Punarintakaija',
    'Punarosella',
    'Punaruusupeippo',
    'Punasiipipeippo',
    'Punatiikeripeippo',
    'Punatuliperä',
    'Punatöpöpeippo',
    'Pyhämaina',
    'Raita-aratti',
    'Raitakylkimanikki',
    'Raitatuliperä',
    'Raitavatsamanikki',
    'Riikinkukkokyyhky',
    'Riisipeippo',
    'Riograndenaratti',
    'Rubiiniamatsoni',
    'Ruohikkoruusupeippo',
    'Ruokoruusupeippo',
    'Ruostevahanokka',
    'Ruskorintamanikki',
    'Ruskoselkämanikki',
    'Ruusuhelmipeippo',
    'Ruusukaijanen',
    'Ruusupeippo',
    'Sahelinvahanokka',
    'Saintlucianamatsoni',
    'Saintvincentinamatsoni',
    'Sambesinkaijanen',
    'Sateenkaariluri',
    'Savanniara',
    'Savanniaratti',
    'Savannikaijanen',
    'Savanniparatiisikaija',
    'Savanniruusupeippo',
    'Savannivahanokka',
    'Seepramanikki',
    'Seeprapeippo',
    'Senegalinkaija',
    'Sepelara',
    'Sepelkaijanen',
    'Seychelliensinikyyhky',
    'Sinikelta-ara',
    'Sinikurkkuara',
    'Sininaamapeippo',
    'Siniotsa-amatsoni',
    'Sinipeippo',
    'Siniposkiamatsoni',
    'Sinipääara',
    'Sinipääaratti',
    'Sinipääpeippo',
    'Idänsiniviiriäinen',
    'Sitruunamanikki',
    'Smaragdiara',
    'Somaliankaija',
    'Suippopyrstöpeippo',
    'Suistomanikki',
    'Sundanparatiisipeippo',
    'Suomumanikki',
    'Suomurosella',
    'Suomuvatsamanikki',
    'Suomuviiltopeippo',
    'Taninbarinkakadu',
    'Tasmanianrosella',
    'Termiittiaratti',
    'Tervapeippo',
    'Timanttikyyhky',
    'Timanttituliperä',
    'Timorinepolettikaija',
    'Timorinmanikki',
    'Timorinriisipeippo',
    'Tuhkavahanokka',
    'Tuliruusupeippo',
    'Tulitikli',
    'Tummatäpläpeippo',
    'Tähtituliperä',
    'Töpöpeippo',
    'Ugandansininokka',
    'Undulaatti',
    'Uudenenglanninmanikki',
    'Uudenirlanninmanikki',
    'Uudenkaledonianparatiisipeippo',
    'Uudenseelanninviherkaija',
    'Vahanokka',
    'Valkohuppumanikki',
    'Valkokakadu',
    'Valkokurkkurosella',
    'Valko-otsa-amatsoni',
    'Valkoperämanikki',
    'Valkovatsamanikki',
    'Viheraranen',
    'Viheraratti',
    'Vihersiipiara',
    'Vihertiikeripeippo',
    'Vihertäpläpeippo',
    'Vihervahanokka',
    'Viiksihemppo',
    'Viiltopeippo',
    'Viiniamatsoni',
    'Viktoriankaijanen',
    'Vitilevunparatiisipeippo',
    'Vilistäjäpeipponen',
  ],
  reptiles: [
    'Viljakäärme',
    'Maitokäärme',
    'Musta rottakäärme',
    'Punakorvakilpikonna',
    'Kuningasboa',
    'Kuningaspyton',
    'Kaliforniankuningaskäärme',
    'Sukkanauhakäärme',
    'Afrikkalainen talokäärme',
    'Viidakkomattopyton',
    'Sateenkaariboa',
    'Leopardigekko',
    'Paksuhäntägekko',
    'Tokeegekko',
    'Madagaskarinpäivägekko',
    'Harjasgekko',
    'Muurigekko',
    'Kultagekko',
    'Kääpiöparta-agama',
    'Parta-agama',
    'Vihervesiagama',
    'Iguaani',
    'Berberiskinkki',
    'Tuliskinkki',
    'Silmäskinkki',
    'Karolinananolislisko',
    'Aavikkopiikkileguaani',
    'Jemeninkameleontti',
    'Jacksoninkameleontti',
    'Helmisisilisko',
    'Juovabasiliski',
    'Viherbasiliski',
    'Teiju',
    'Miekkahäntätulilisko',
    'Lehmälehtisammakko',
    'Kiinankellosammakko',
    'Viherlehtisammakko',
    'Valkohuulilehtisammakko',
    'Korallisormisammakko',
    'Punasilmälehtisammakko',
    'Valetomaattisammakko',
    'Chaconsarvisammakko',
    'Agakonna',
    'Puhvelisammakko',
    'Kultamantella',
    'Namumyrkkysammakko',
    'Värjärinuolimyrkkysammakko',
    'Vihernuolimyrkkysammakko',
    'Sininuolimyrkkysammakko',
    'Golfodulcenmyrkkysammakko',
    'Kekälenuolimyrkkysammakko',
    'Keltanuoli',
    'Aksolotli',
    'Kääpiökynsisammakko',
    'Seepratarantula',
    'Brasiliantarantula',
    'Kiharakarvainen tarantula',
    'Meksikontulijalka',
    'Meksikonpunapolvitarantula',
    'Brasilianvalkopolvi',
    'Malesianmetsäskorpioni',
    'Japaninjättiskorpioni',
    'Kiinankultaskorpioni',
    'Kreikankilpikonna',
    'Nelivarvaskilpikonna',
    'Kannuskilpikonna',
    'Leopardikilpikonna',
    'Hiilikilpikonna',
    'Reunuskilpikonna',

  ],
  fishes: [
    'Helmirihmakala',
    'Safiirikala',
    'Hohtomiljoonakala',
    'Keltamolli',
    'Kirjoplaty',
    'Kyttyräkala',
    'Kääpiöhammaskarppi',
    'Metallihammaskarppi',
    'Miekkapyrstö',
    'Miljoonakala',
    'Moskiittokala',
    'Mustamolli',
    'Mustavatsamolli',
    'Pastellimolli',
    'Pilkkukala',
    'Platy',
    'Purjemolli',
    'Vankkamolli',
    'Veitsihammaskarppi',
    'Verkkohammaskarppi',
    'Hohtoviiripyrstö',
    'Juovavaanija',
    'Kaksijuovaviiripyrstö',
    'Kiilavaanija',
    'Kirjoviiripyrstö',
    'Kultakylkiviiripyrstö',
    'Käpykilli',
    'Loistoviiripyrstö',
    'Punajuovaviiripyrstö',
    'Safiiriviiripyrstö',
    'Sinivaanija',
    'Siniviiripyrstö',
    'Teräsviiripyrstö',
    'Timanttivaanija',
    'Timanttiviiripyrstö',
    'Turkoosiviiripyrstö',
    'Täpläviiripyrstö',
    'Kultakala',
    'Ruutana',
    'Karppi',
    'Salakka',
    'Leväbarbi',
    'Juovaimubarbi',
    'Tulipyrstö',
    'Tulievä',
    'Kuonobarbi',
    'Mustalabeo',
    'Haibarbi',
    'Hopeabarbi',
    'Ilveilijäbarbi',
    'Keltabarbi',
    'Kirjobarbi',
    'Kirsikkabarbi',
    'Loistobarbi',
    'Messinkibarbi',
    'Punapääbarbi',
    'Rihmabarbi',
    'Tiikeribarbi',
    'Verkkobarbi',
    'Viisiraitabarbi',
    'Hohtorasbora',
    'Hohtoseeprakala',
    'Kardinaalikala',
    'Kiilakylki',
    'Kultaseeprakala',
    'Kääpiörasbora',
    'Pilkkuseeprakala',
    'Punapyrstörasbora',
    'Raitaseeprakala',
    'Rusokiilakylki',
    'Saksipyrstö',
    'Seeprakala',
    'Siniseeprakala',
    'Täpläseeprakala',
    'Piikkisilmä',
    'Kärsänuoliainen',
    'Siaminpiikkisilmä',
    'Aasianmutakala',
    'Harlekiininuoliainen',
    'Kääpiönuoliainen',
    'Tiikerinuoliainen',
    'Touhunuoliainen',
    'Verkkonuoliainen',
    'Harmaanuoliainen',
    'Kaarinuoliainen',
    'Pilkkuimunuoliainen',
    'Pisteimunuoliainen',
    'Levänuoliainen',
    'Kynäkala',
    'Tulisuppusuu',
    'Kolmilinjatetra',
    'Juovatapparakala',
    'Kääpiötapparakala',
    'Marmoritapparakala',
    'Punapiraija',
    'Kongontetra',
    'Tulipyrstötetra',
    'Punaevätetra',
    'Lasitetra',
    'Hehkujuovatetra',
    'Hehkutetra',
    'Intiaaninsulka',
    'Kardinaalitetra',
    'Keisaritetra',
    'Kerritetra',
    'Kirsikkatetra',
    'Korutetra',
    'Kulmatetra',
    'Kuparitetra',
    'Kääpiösuppusuu',
    'Lipputetra',
    'Lyhtytetra',
    'Loistotetra',
    'Musta-aavetetra',
    'Mustaneontetra',
    'Mustatetra',
    'Neontetra',
    'Pikkutetra',
    'Pikkukirsikkatetra',
    'Puna-aavetetra',
    'Punapäätetra',
    'Punasuutetra',
    'Punatetra',
    'Roiskuttajatetra',
    'Rubiinitetra',
    'Ruusuaavetetra',
    'Ruusutetra',
    'Safiiritetra',
    'Sinineontetra',
    'Sinitetra',
    'Sitruunatetra',
    'Timanttitetra',
    'Juovakääpiöahven',
    'Keltakääpiöahven',
    'Kääpiöakara',
    'Loistokääpiöahven',
    'Palettikääpiöahven',
    'Pandakääpiöahven',
    'Perhoskirjoahven',
    'Rubiinikääpiöahven',
    'Rusokirjoahven',
    'Timanttikirjoahven',
    'Tulikääpiöahven',
    'Töyhtökääpiöahven',
    'Avainkirjoahven',
    'Kiekkokala',
    'Lehtikala',
    'Lippukirjoahven',
    'Lippulehtikala',
    'Pikkulehtikala',
    'Ruostekirjoahven',
    'Sateenkaariahven',
    'Seeprakirjoahven',
    'Sinikirjoahven',
    'Sinileukakirjoahven',
    'Tulisuu',
    'Valjaskirjoahven',
    'Helmikirjoahven',
    'Riikinkukkokirjoahven',
    'Saumakirjoahven',
    'Teksasinkirjoahven',
    'Turkoosikirjoahven',
    'Keltamalawinahven',
    'Kuusiraitamalawinahven',
    'Seepramalawinahven',
    'Keltakotiloahven',
    'Kultakoruahven',
    'Seeprakotiloahven',
    'Pilkkutimanttiahven',
    'Purppurasuuhautoja',
    'Kultalasiahven',
    'Helmiäistonkija',
    'Loistokiipijäkala',
    'Paratiisikala',
    'Korurihmakala',
    'Taistelukala',
    'Hunajarihmakala',
    'Huulirihmakala',
    'Juovarihmakala',
    'Keltarihmakala',
    'Kääpiörihmakala',
    'Sinirihmakala',
    'Pusukala',
    'Sundanlasimonni',
    'Panssarimonni',
    'Lippuritarimonni',
    'Ritarimonni',
    'Rantamonni',
    'Haarniskamonni',
    'Pistehaarniskamonni',
    'Aaltomonninen',
    'Albiinomonninen',
    'Aurinkomonninen',
    'Harmaakylkimonninen',
    'Helmimonninen',
    'Helomonninen',
    'Hietamonninen',
    'Hohtomonninen',
    'Jaguaarimonninen',
    'Kaarimonninen',
    'Kiiltomonninen',
    'Kirjomonninen',
    'Korumonninen',
    'Kulmajuovamonninen',
    'Kulmamonninen',
    'Kultapiikkimonninen',
    'Kultapäämonninen',
    'Kuparimonninen',
    'Kääpiömonninen',
    'Leopardimonninen',
    'Mustaselkämonninen',
    'Naamiomonninen',
    'Nokimonninen',
    'Nuolimonninen',
    'Pandamonninen',
    'Pantterimonninen',
    'Pikkumonninen',
    'Pilkkumonninen',
    'Punamonninen',
    'Purjemonninen',
    'Ristimonninen',
    'Rusomonninen',
    'Ruutumonninen',
    'Safiirimonninen',
    'Sihtimonninen',
    'Sinimonninen',
    'Somamonninen',
    'Sysievämonninen',
    'Täplämonninen',
    'Valkopilkkumonninen',
    'Vihermonninen',
    'Ylänkömonninen',
    'Appelsiinipleko',
    'Helmipaholaispleko',
    'Helmipartamonni',
    'Isopurjepleko',
    'Salamanteripleko',
    'Tummaleväpleko',
    'Täpläpartamonni',
    'Juovanuolimonni',
    'Katinkultamonni',
    'Perunnuolimonni',
    'Raitanuolimonni',
    'Seepranuolimonni',
    'Helmiripsimonni',
    'Koruripsimonni',
    'Mokkaripsimonni',
    'Njassanripsimonni',
    'Keltaripsimonni',
    'Rengasripsimonni',
    'Seepraripsimonni',
    'Selkäuimari',
    'Siimaripsimonni',
    'Sokkeloripsimonni',
    'Suklaaripsimonni',
    'Sulkaripsimonni',
    'Tummaripsimonni',
    'Täpläripsimonni',
    'Lapamonni',
    'Tiikerilapamonni',
    'Juovaokamonni',
    'Kampaokamonni',
    'Haimonni',
    'Kääpiöpallokala',
    'Punapyrstöpallokala',
    'Kimalaispallokala',
    'Aurinkopallokala',
    'Borneonpallokala',
    'Pisamapallokala',
    'Töppöpallokala',
    'Kyhmypallokala',
  ],
  others: [
    'Fretti',
    'Tarantella',
    'Hevonen',
    'Lammas',
    'Nauta',
    'Vuohi',
    'Kana',
    'Kukko',
    'Kani',
    'Sika',
    'Madagaskarintorakka',
    'Erakkorapu',
    'Tuhatjalkainen',
    'Orkidearukoilijasirkka',
    'Intiankukkarukoilijasirkka',
  ]
};